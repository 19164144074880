'use strict';

import $ from 'jquery';

const Modernizr = { picture: true };

export default function () {
    const desktopMedia = window.matchMedia('(min-width: 992px)');
    const mobilMedia = window.matchMedia('(max-width: 991px)');

    function importDesktopNav() {
        import('./components/navigation').then(init => {
            return init.navigation();
        }).catch(err => {
            console.warn(`Chunk navigation loading failed: ${err}`);
        });
    }

    function importMobileNav() {
        import('./components/mobileNav').then(init => {
            return init.mobileNav();
        }).catch(err => {
            console.warn(`Chunk mobileNav loading failed: ${err}`);
        });
    }

    if (!Modernizr.picture) {
        import('picturefill').catch(err => {
            console.warn(`Chunk picturefill loading failed: ${err}`);
        })
    }

    // only load navigation for current viewport
    if (desktopMedia.matches) {
        importDesktopNav();
    } else {
        importMobileNav();
    }
    desktopMedia.addListener(importDesktopNav);
    mobilMedia.addListener(importMobileNav);

    import('../commons').then(home => {
        return home.init();
    }).catch(err => {
        console.warn(`Chunk commons loading failed: ${err}`);
    })

    if ($('.home').length) {
        import('./components/home').then(home => {
            return home.init();
        }).catch(err => {
            console.warn(`Chunk home loading failed: ${err}`);
        })
    }

    if ($('.newsletter-form').length) {
        import('./components/newsletter').then(newsletter => {
            return newsletter.init();
        }).catch(err => {
            console.warn(`Chunk newsletter loading failed: ${err}`);
        })
    }

    if ($('.enhance').length) {
        import('./components/forms').then(form => {
            return form.initSelect();
        }).catch(err => {
            console.warn(`Chunk forms loading failed: ${err}`);
        })
    }

    if ($('.form-group.has-danger').length) {
        import('./components/forms').then(form => {
            return form.formCheck();
        }).catch(err => {
            console.warn(`Chunk forms loading failed: ${err}`);
        })
    }

    if ($('.products-container, .recipes-container').length) {
        import('./components/overview').then(overview => {
            return overview.init();
        }).catch(err => {
            console.warn(`Chunk overview loading failed: ${err}`);
        })
    }

    if ($('.lightbox').length) {
        import('./components/lightbox').then(lightbox => {
            return lightbox.initPhotoSwipe('.lightbox');
        }).catch(err => {
            console.warn(`Chunk lightbox loading failed: ${err}`);
        })
    }

    if ($('.banner .slider').length) {
        import('./components/slider').then(slider => {
            return slider.bannerSlider();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('.product-slider').length) {
        import('./components/slider').then(slider => {
            return slider.productSlider();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('.image-slider').length) {
        import('./components/slider').then(slider => {
            return slider.imageSlider();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('.content-slider').length) {
        import('./components/slider').then(slider => {
            return slider.contentSlider();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('.simple-slider').length) {
        import('./components/slider').then(slider => {
            return slider.simple();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('body.detail').length) {
        import('./components/detailSite').then(detail => {
            return detail.init();
        }).catch(err => {
            console.warn(`Chunk slider loading failed: ${err}`);
        })
    }

    if ($('.date-picker').length || $('.birthday-picker').length) {
        import('./components/datePicker.js').then(picker => {
            return picker.init();
        }).catch(err => {
            console.warn(`Chunk datepicker loading failed: ${err}`);
        })
    }

    if ($('.landingpage').length) {
        import('./components/landingpage.js').then(landingpage => {
            return landingpage.init();
        }).catch(err => {
            console.warn(`Chunk landingpage loading failed: ${err}`);
        })
    }

    if ($('.meat-use').length) {
        import('./components/meat.js').then(meat => {
            return meat.init();
        }).catch(err => {
            console.warn(`Chunk meat loading failed: ${err}`);
        })
    }

    if ($('.animaldetail').length) {
        import('./components/animalDetail.js').then(animaldetail => {
            return animaldetail.init();
        }).catch(err => {
            console.warn(`Chunk animalDetail loading failed: ${err}`);
        })
    }

    if ($('#sponsorform').length) {
        import('./components/sponsorForm.js').then(sponsorForm => {
            return sponsorForm.init();
        }).catch(err => {
            console.warn(`Chunk sponsorForm loading failed: ${err}`);
        })
    }

    if ($('#history-bell').length) {
        import('./components/history.js').then(history => {
            return history.init();
        }).catch(err => {
            console.warn(`Chunk history loading failed: ${err}`);
        })
    }

    if ($('#fileupload').length) {
        import('./components/fileUpload.js').then(upload => {
            return upload.init();
        }).catch(err => {
            console.warn(`Chunk fileUpload loading failed: ${err}`);
        })
    }

    if ($('.bbq-lp').length) {
        import('./components/bbq/bbq-landingpage.js').then(upload => {
            return upload.init();
        }).catch(err => {
            console.warn(`Chunk bbq-landingpage loading failed: ${err}`);
        })
    }

    if ($('#treveltimes-map').length) {
        import('./components/travelTimesMap.js')
            .catch(err => {
                console.warn(`Chunk travelTimesMap loading failed: ${err}`);
            })
    }
};